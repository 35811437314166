body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: grid;
  grid-template-columns: 600px auto;
  grid-gap: 32px;
  padding: 0 20px;
}

#threekit-player {
  height: calc(100vh - 200px);
}

.attribute-heading {
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 18px;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  padding: 8px;

  margin: 0 6px 12px 6px;
  border: 1px solid #dddddd;
  cursor: pointer;
  border-radius: 2px;
}

.card:hover {
  border-color: #1890ff;
}

.card > div {
  text-align: center;
}

.card img {
  height: 72px;
  width: 72px;
  margin-bottom: 8px;
}
